import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import PageSpinner from "../components/loaders/page-spinner";
import useRequest from "../hooks/use-request";
import apiRoutes from "../routes/api-routes";
import { authActions } from "./auth-reducer";
let time;
const StoreInitialize = (props) => {
  const { sendRequest, isLoading } = useRequest({
    errorHandle: false,
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const { token, expiredAt } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  // Initialize store values
  useEffect(() => {
    let localToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

    const initialize = async () => {
      if (localToken) {
        const response = await sendRequest({
          url: apiRoutes.ADMIN_INITIALIZE,
          token: localToken,
          method: "POST",
        });
        if (response) {
          const data = response;
          dispatch(
            authActions.initialize({
              token: localToken,
              isAuthenticated: true,
              roles: data.roles,
              accessibleWebsites: data.accessible_websites,
              user: data.user,
              expiredAt: data.expired_at,
            })
          );

          // console.log(
          //   ((Date.parse(data.expired_at) - Date.now()) / (1000 * 60 * 60)) % 24
          // );
        } else {
          dispatch(authActions.onLogout());
        }
      }
      setIsInitialized(true);
    };
    initialize();
  }, []);

  // Referesh Token
  useEffect(() => {
    if (token && expiredAt) {
      const refreshToken = async () => {
        let response = await sendRequest({
          url: apiRoutes.ADMIN_REFRESH_TOKEN,
          method: "POST",
        });

        if (response) {
          dispatch(authActions.refreshToken(response));
        }
      };

      let utcNow = new Date(new Date().toISOString()).getTime();

      // 1000 * 60 * 10 => 10 Minitues
      let milli = new Date(expiredAt).getTime() - utcNow - 1000 * 60 * 10;
      time = setTimeout(() => {
        if (token) {
          refreshToken();
        }
      }, milli);

      return () => {
        clearTimeout(time);
      };
    }
  }, [dispatch, expiredAt, sendRequest, token]);

  return <>{isInitialized ? props.children : <PageSpinner />}</>;
};

export default StoreInitialize;
