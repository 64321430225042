import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Component";
import PageContainer from "../../layouts/dashboard/page-container/PageContainer";
import routes from "../../routes/routes-data";

const Error500 = ({ error, resetErrorBoundary }) => {
  return (
    <PageContainer>
      <div className="nk-block nk-block-middle wide-md mx-auto">
        <div className="nk-block-content nk-error-ld text-center">
          <h1 className="nk-error-head">500</h1>
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">Oops! Server Error</h3>
            <p className="nk-error-text">{error.message}</p>
            <Link to={routes.DASHBOARD_MAIN} onClick={resetErrorBoundary}>
              <Button color="primary" size="lg" className="mt-2">
                Back To Dashboard
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Error500;
