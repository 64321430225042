import React, { useCallback, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Step, Steps } from "react-step-builder";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import HeadSection from "../../components/admin-page-components/HeadSection";
import {
  // BlockBetween,
  // BlockDes,
  // BlockHead,
  // BlockHeadContent,
  // BlockTitle,
  // Button,
  // Icon,
  PreviewCard,
} from "../../components/Component";
import Overlay from "../../components/loaders/page-overlay";
import useForm from "../../hooks/use-form";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import apiRoutes from "../../routes/api-routes";
import routes from "../../routes/routes-data";
// import { VariantTypes } from "../../utils/statics";
import Details from "./create-update-partials/details";

const CouponCreateUpdate = (props) => {
  const { inputs, setFields } = useForm({
    code: "",
    type: "",
    value: "",
    expiry_date: "",
    website: "",
    // is_featured: false,
  });

  const { isLoading: initialLoading, sendRequest: sendInitialRequest } = useRequest();

  const onUnmount = (fields) => {
    setFields((prev) => ({ ...prev, ...fields }));
  };

  let location = useLocation();
  let { couponId } = useParams();

  const getCoupon = useCallback(async () => {
    try {
      let response = await sendInitialRequest({
        url: apiRoutes.COUPON_SHOW(couponId),
      });
      if (response) {
        setFields({
          code: response.code,
          type: response.type,
          value: response.value,
          expiry_date: response.expiry_date,
          website: response.website,
          // is_featured: response.is_featured,
        });
      }
    } catch (error) {
      console.error("Failed to fetch coupon:", error);
      // Handle error (e.g., show notification to user)
    }
  }, [couponId, sendInitialRequest, setFields]);

  useEffect(() => {
    setFields({
      code: "",
      type: "",
      value: "",
      expiry_date: "",
      website: "",
      // is_featured: false,
    });
  }, [location.pathname, setFields]);

  useEffect(() => {
    if (couponId) {
      getCoupon();
    }
  }, [getCoupon, couponId]);

  return (
    <Content>
      <HeadSection
        title={`${couponId ? "Edit" : "Create"} Coupon`}
        right={
          <Breadcrumb className="breadcrumb-arrow">
            <BreadcrumbItem>
              <Link to={routes.COUPON_INDEX}>Coupons</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {couponId ? "Edit" : "Create"}
            </BreadcrumbItem>
          </Breadcrumb>
        }
      />

      {initialLoading && (
        <PreviewCard>
          <Overlay spinner={true} bg />
        </PreviewCard>
      )}

      {!initialLoading && (
        <PreviewCard>
          <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
            <Steps
              config={{
                navigation: {
                  component: (props) => (
                    <div className="steps clearfix">
                      <ul>
                        <li
                          className={
                            props.current >= 1 ? "first done" : "first"
                          }
                        >
                          <a
                            href="#wizard-01-h-0"
                            onClick={(ev) => ev.preventDefault()}
                            style={{ cursor: "default" }}
                          >
                            {/* <span className="number">01</span>{" "}
                            <h5>Details</h5> */}
                          </a>
                        </li>
                      </ul>
                    </div>
                  ),
                  location: "before",
                },
              }}
            >
              <Step
                title="Details"
                component={React.memo((props) => (
                  <Details
                    stepState={props}
                    fields={inputs}
                    onUnmount={onUnmount}
                    couponId={couponId}
                  />
                ))}
              />
            </Steps>
          </div>
        </PreviewCard>
      )}
    </Content>
  );
};

export default CouponCreateUpdate;