import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import PriceRange from "../../components/html/PriceRange";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import {
  capitalizeText,
  imageKitLoader,
  searchParamsToObject,
} from "../../utils/methods";
import HeadSection from "../../components/admin-page-components/HeadSection";
import Button from "../../components/button/Button";
import Swal from "sweetalert2";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import routes from "../../routes/routes-data";
import { useSelector } from "react-redux";
import Image from "../../components/image/Image";

const ProductsIndex = (props) => {
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [products, setProducts] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();
  const { sendRequest: sendRequestFeatured } = useRequest();

  let location = useLocation();

  let websitesOption = useSelector(
    (store) => store.auth.accessibleWebsites
  ).map((web) => ({
    label: capitalizeText(web),
    value: web,
  }));

  const getProducts = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.PRODUCT_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response) {
      setProducts(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.PRODUCT_DELETE(id),
        method: "DELETE",
      });
      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Product has been deleted successfully");
        getProducts();
      }
    }
    return true;
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  const onFeaturedClick = async (id) => {
    setProducts((prev) => {
      return prev?.map((cat) => {
        if (cat.id == id) {
          return {
            ...cat,
            is_featured: !cat.is_featured,
          };
        } else {
          return cat;
        }
      });
    });
    await sendRequestFeatured({
      url: apiRoutes.PRODUCT_UPDATE_FEATURED(id),
      method: "PUT",
    });
  };

  useEffect(() => {
    setProducts([]);
  }, [location.pathname]);
  useEffect(() => {
    getProducts();
  }, [getProducts, searchParams]);

  return (
    <>
      <Head title="Products"></Head>
      <Content>
        <HeadSection
          title={"Products"}
          right={
            <Button
              to={routes.PRODUCT_CREATE}
              className="d-inline-flex"
              color="primary"
            >
              <Icon name="plus"></Icon>
              <span className="d-none d-md-block">Add Product</span>
            </Button>
          }
        />

        <PageTable
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchPlaceholder={"Search by product name, id, or website"}
          title={"Main Products"}
          isLoading={isLoading}
          error={error}
          headers={[
            "name",
            "SKU",
            "price",
            "stock",
            "website",
            <TooltipComponent
              direction={"top"}
              id="is_fav"
              icon={"star-fill"}
              text="Mark product as featured"
              iconClass={"tb-asterisk"}
            ></TooltipComponent>,
          ]}
          actions={true}
          body={products?.map((product) => [
            <span className="tb-product">
              <Image
                onError={(e) => (e.target.src = "upload.gif")}
                src={imageKitLoader(product?.main_image.path, 48)}
                alt="product"
                className="thumb"
                style={{
                  width: "48px",
                  height: "48px",
                  objectFit: "cover",
                }}
              />
              <span className="title text-capitalize">{product.name}</span>
            </span>,
            product.sku,
            <p>
              <PriceRange product={product} />
            </p>,
            product.stock,
            capitalizeText(product.website),
            <div className="asterisk tb-asterisk">
              <div
                className={product.is_featured ? "active" : ""}
                onClick={() => onFeaturedClick(product.id)}
              >
                <Icon name="star" className="asterisk-off"></Icon>
                <Icon name="star-fill" className="asterisk-on"></Icon>
              </div>
            </div>,
            [
              {
                dropdown: (
                  <DropdownItem tag={Link} to={routes.PRODUCT_EDIT(product.id)}>
                    <Icon name="edit"></Icon>
                    <span>Edit</span>
                  </DropdownItem>
                ),
                tooltip: (
                  <li className="nk-tb-action-hidden">
                    <TooltipComponent
                      tag={Link}
                      to={routes.PRODUCT_EDIT(product.id)}
                      containerClassName="btn btn-trigger btn-icon"
                      id={"edit" + product.id}
                      icon="edit"
                      direction="top"
                      text="Edit Product"
                    />
                  </li>
                ),
              },
              {
                dropdown: (
                  <DropdownItem
                    tag="a"
                    href="#"
                    onClick={() => onDeleteClick(product.id)}
                  >
                    <Icon name="trash-empty"></Icon>
                    <span>Delete</span>
                  </DropdownItem>
                ),
                tooltip: (
                  <li
                    className="nk-tb-action-hidden"
                    onClick={() => onDeleteClick(product.id)}
                  >
                    <TooltipComponent
                      tag="a"
                      containerClassName="btn btn-trigger btn-icon"
                      id={"delete" + product.id}
                      icon="trash-empty"
                      direction="top"
                      text="Delete Product"
                    />
                  </li>
                ),
              },
            ],
          ])}
          paginationData={paginationData}
          filterOptions={[
            {
              label: "wesbite",
              name: "website",
              options: [
                {
                  label: "all",
                  value: "",
                },
                ...websitesOption,
              ],
            },
          ]}
        />
      </Content>
    </>
  );
};

export default ProductsIndex;
