import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/main.css";
import Error500 from "./pages/error/500";
import apiRoutes from "./routes/api-routes";

import Routes from "./routes/routes";

import store from "./store/store";
import StoreInitialize from "./store/store-initialize";
import { AxiosRequest } from "./utils/request";
const on500Error = (error, info) => {
  new AxiosRequest().sendRequest({
    url: apiRoutes.ERROR_STORE,
    method: "POST",
    body: {
      message: error.message,
      trace: error.stack,
    },
  });
};

function App() {
  return (
    <Provider store={store}>
      <StoreInitialize>
        <ErrorBoundary FallbackComponent={Error500} onError={on500Error}>
          <Routes />

          <ToastContainer
            style={{
              right: "8rem",
            }}
          />
        </ErrorBoundary>
      </StoreInitialize>
    </Provider>
  );
}

export default App;
