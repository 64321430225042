import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { DropdownItem, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import routes from "../../routes/routes-data";
import { searchParamsToObject } from "../../utils/methods";
import HeadSection from "../../components/admin-page-components/HeadSection";
import Button from "../../components/button/Button";
import CreatUpdateModal from "./partials/create-update-modal";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const CouponIndex = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [couponsData, setCouponsData] = useState([]);
  const [mainCoupon, setMainCoupon] = useState(null);
  const { isLoading, sendRequest, error } = useRequest();
  const { sendRequest: sendRequestFeatured } = useRequest();

  let websitesOption = useSelector(
    (store) => store.auth.accessibleWebsites
  ).map((web) => ({
    label: web.toLowerCase(),
    value: web,
  }));

  const getCouponData = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.COUPON_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response?.data) {
      setMainCoupon(response.main);
      setCouponsData(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page !== 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.COUPON_DELETE(id),
        method: "DELETE",
      });
      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Coupon has been deleted successfully");
        getCouponData();
      }
    }
    return true;
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  // const onFeaturedClick = async (id) => {
  //   setCouponsData((prev) => {
  //     return prev?.map((coup) => {
  //       if (coup.id === id) {
  //         return {
  //           ...coup,
  //           is_featured: !coup.is_featured,
  //         };
  //       } else {
  //         return coup;
  //       }
  //     });
  //   });

  //   await sendRequestFeatured({
  //     url: apiRoutes.COUPON_UPDATE_FEATURED(id),
  //     method: "PUT",
  //   });
  // };

  useEffect(() => {
    getCouponData();
  }, [getCouponData, searchParams]);

  return (
    <>
      <Head title="Coupons"></Head>
      <Content>
        <HeadSection
          left={
            <Breadcrumb className="breadcrumb-arrow">
              <BreadcrumbItem>
                <Link
                  to={{
                    pathname: routes.COUPON_INDEX,
                    search: `?website=${mainCoupon?.website}`,
                  }}
                >
                  Coupon
                </Link>
              </BreadcrumbItem>
              {/* <BreadcrumbItem active>{mainCoupon?.name}</BreadcrumbItem> */}
            </Breadcrumb>
          }
          title={"Coupons"}
          right={
            <Button
              className="d-inline-flex"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              <Icon name="plus"></Icon>
              <span className="d-none d-md-block">
                Add
                {/* {parentId ? "Sub" : "Main"} */}
              </span>
            </Button>
          }
        />
        <PageTable
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchPlaceholder={"Search by Coupons code, value"}
          title="All Coupons"
          isLoading={isLoading}
          error={error}
          headers={[
            "id",
            "code",
            "type",
            "value",
            "expiry_date",
            "website",
            // <TooltipComponent
            //   direction={"top"}
            //   id="is_fav"
            //   icon={"star-fill"}
            //   text="Mark category as featured"
            //   iconClass={"tb-asterisk"}
            // ></TooltipComponent>,
          ]}
          actions={true}
          body={couponsData?.map((coupon) => {
            return [
              coupon.id,
              coupon.code,
              coupon.type,
              coupon.value,
              coupon.expiry_date,
              coupon.website,

              // <div className="asterisk tb-asterisk">
              //   <div
              //     className={coupon.is_featured ? "active" : ""}
              //     onClick={() => onFeaturedClick(coupon.id)}
              //   >
              //     <Icon name="star" className="asterisk-off"></Icon>
              //     <Icon name="star-fill" className="asterisk-on"></Icon>
              //   </div>
              // </div>,
              [
                // edit
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => {
                        setSelectedCoupon(coupon);
                        setShowModal(true);
                      }}
                    >
                      <Icon name="edit"></Icon>
                      <span>Edit</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        setSelectedCoupon(coupon);
                        setShowModal(true);
                      }}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"edit" + coupon.id}
                        icon="edit"
                        direction="top"
                        text="Edit Category"
                      />
                    </li>
                  ),
                },
                // delete
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => onDeleteClick(coupon.id)}
                    >
                      <Icon name="trash-empty"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => onDeleteClick(coupon.id)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"delete" + coupon.id}
                        icon="trash-empty"
                        direction="top"
                        text="Delete Category"
                      />
                    </li>
                  ),
                },
              ],
            ];
          })}
          paginationData={paginationData}
        />
      </Content>

      <CreatUpdateModal
        coupon={selectedCoupon}
        show={showModal}
        websitesOption={websitesOption}
        onClose={() => {
          setShowModal(false);
          setSelectedCoupon(null);
        }}
        onSuccess={() => getCouponData()}
      />
    </>
  );
};

export default CouponIndex;
