import { toast } from "react-toastify";
// import { Icon } from "../components/Component";
// import { CloseButton } from "react-toastify/dist/components";

export const addAlert = (type, message, title = null) => {
  toast(message, {
    type: type,
    position: "bottom-right",
    autoClose: true,
    // hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: ({ closeToast }) => (
      <span
        className="btn-trigger toast-close-button"
        onClick={closeToast}
        role="button"
      >
        <em className="icon ni ni-cross"></em>
      </span>
    ),
  });
};
