export const AdminRoles = {
  SUPER_ADMIN: "SUPER_ADMIN",
  SCKALI_ADMIN: "SCKALI_ADMIN",
  SCKALI_DESGIN_ADMIN: "SCKALI_DESGIN_ADMIN",
  SCKALI_BEAUTY_ADMIN: "SCKALI_BEAUTY_ADMIN",
  SCKALI_DAUM_ADMIN: "SCKALI_DAUM_ADMIN",
  SCKALI_HOMELINE_ADMIN: "SCKALI_HOMELINE_ADMIN",
  CKALI_KIDS_ADMIN: "CKALI_KIDS_ADMIN",
  ARGANSUS_ADMIN: "ARGANSUS_ADMIN",
  TARUTTI_ADMIN: "TARUTTI_ADMIN",
};

export const Websites = {
  SCKALI_MAIN: "sckali-main",
  SCKALI_DESGIN: "sckali-design",
  SCKALI_BEAUTY: "sckali-beauty",
  SCKALI_DAUM: "sckali-daum",
  SCKALI_HOMELINE: "sckali-homeline",
  CKALI_KIDS: "ckali-kids",
  ARGANSUS: "argansus",
  TARUTTI: "tarutti",
};

export const ProductOptionTypes = {
  TEXT: "TEXT",
  COLOR: "COLOR",
};

export const shippingWeightColumns = [
  {
    column: "0-500",
    label: "0 - 0.5kg",
    max: 500,
  },
  {
    column: "500-1000",
    label: "0.5kg - 1kg",
    max: 1000,
  },
  {
    column: "1001-1500",
    label: "1kg- 1.5kg",
    max: 1500,
  },
  {
    column: "1501-2000",
    label: "1.5kg - 2kg",
    max: 2000,
  },
  {
    column: "2001-2500",
    label: "2kg - 2.5kg",
    max: 2500,
  },
  {
    column: "2501-3000",
    label: "2.5kg - 3kg",
    max: 3000,
  },
  {
    column: "3001-3500",
    label: "3kg - 3.5kg",
    max: 3500,
  },
  {
    column: "3501-4000",
    label: "3.5kg - 4kg",
    max: 4000,
  },
  {
    column: "4001-4500",
    label: "4kg - 4.5kg",
    max: 4500,
  },
  {
    column: "4501-5000",
    label: "4.5kg - 5kg",
    max: 5000,
  },
  {
    column: "5001-5500",
    label: "5kg - 5.5kg",
    max: 5500,
  },
  {
    column: "5501-6000",
    label: "5.5kg - 6kg",
    max: 6000,
  },
  {
    column: "6001-6500",
    label: "6kg - 6.5kg",
    max: 6500,
  },
  {
    column: "6501-7000",
    label: "6.5kg - 7kg",
    max: 7000,
  },
  {
    column: "7001-7500",
    label: "7kg - 7.5kg",
    max: 7500,
  },
  {
    column: "7501-8000",
    label: "7.5kg - 8kg",
    max: 8000,
  },
  {
    column: "8001-8500",
    label: "8kg - 8.5kg",
    max: 8500,
  },
  {
    column: "8501-9000",
    label: "8.5kg - 9kg",
    max: 9000,
  },
  {
    column: "9001-9500",
    label: "9kg - 9.5kg",
    max: 9500,
  },
  {
    column: "9501-10000",
    label: "9.5kg - 10kg",
    max: 10000,
  },
  {
    column: "10001-30000",
    label: "10kg - 30kg (per 0.5kg)",
    max: 30000,
  },
  {
    column: "30001-70000",
    label: "30kg - 70kg (per 1kg)",
    max: 70000,
  },
];

export const reduxRequestStatus = {
  PENDING: "pending",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
};

export const VariantTypes = {
  STANDARD_VARIANT: "standard",
  CUSTOM_VARIANT: "custom",
};

export const PaymentMethods = {
  PAYPAL: "PAYPAL",
  CASH_ON_DELIVERY: "CASH_ON_DELIVERY",
  MY_FATOORAH: "MY_FATOORAH",
};

export const AddressTypes = {
  CUSTOMER_SHIPPING: "CUSTOMER_SHIPPING",
  CUSTOMER_BILLING: "CUSTOMER_BILLING",
  ORDER_SHIPPING: "ORDER_SHIPPING",
  ORDER_BILLING: "ORDER_BILLING",
};

export const PaymentStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  PARTIALLY_REFUNDED: "PARTIALLY_REFUNDED",
  REFUNDED: "REFUNDED",
  PAID: "PAID",
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
};

export const OrderStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
};

export const OrderStatusIcons = {
  IN_PROGRESS: "reload",
  SHIPPED: "wallet-out",
  DELIVERED: "check-circle",
  CANCELLED: "na",
};

export const EventRegistrationStatus = {
  AVAILABLE: "AVAILABLE",
  BLOCKED: "BLOCKED",
};

export const CustomerStatus = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
};

export const DirectMessageStatus = {
  PENDING: "PENDING",
  ARCHIVED: "ARCHIVED",
};

export const BannerTypes = {
  MAIN: "main",
  SLOT: "slot",
};
