import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import HeadSection from "../../components/admin-page-components/HeadSection";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import routes from "../../routes/routes-data";
import { searchParamsToObject } from "../../utils/methods";

const EventRegistrations = (props) => {
  const { eventId } = useParams();
  const location = useLocation();
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [registrations, setRegistrations] = useState([]);
  const [event, setEvent] = useState(null);

  const { isLoading, sendRequest, error } = useRequest();
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/
  const getRegistrations = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.EVENT_REGISTRATION_INDEX(eventId),
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response) {
      setEvent(response.event);
      let event_reg = response.event_registrations;
      setRegistrations(event_reg.data?.length > 0 ? event_reg.data : []);
      delete event_reg.data;
      setPaginationData(event_reg.last_page !== 1 ? event_reg : null);
    }
  }, [eventId, searchParams, sendRequest]);
  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/
  useEffect(() => {
    setRegistrations([]);
  }, [location.pathname]);

  useEffect(() => {
    getRegistrations();
  }, [getRegistrations, searchParams]);
  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return (
    <>
      <Head title={event?.name ?? "Loading..."}></Head>
      <Content>
        <HeadSection
          title={event?.name ?? "Loading..."}
          right={
            <Breadcrumb className="breadcrumb-arrow">
              <BreadcrumbItem>
                <Link to={routes.EVENTS_INDEX}>Events</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {event?.name ?? "Loading..."}
              </BreadcrumbItem>
            </Breadcrumb>
          }
        />

        {
          <PageTable
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            searchPlaceholder={"Search by event name, id"}
            title={"Main Events"}
            isLoading={isLoading}
            error={error}
            headers={["Reg. ID", "name", "QID", "Whatsapp", "Customer ID"]}
            actions={false}
            body={registrations?.map((reg) => {
              return [
                reg.id,
                reg.full_name,
                reg.qid,
                reg.whatsapp_number,
                reg.customer_id,
              ];
            })}
            paginationData={paginationData}
          />
        }
      </Content>
    </>
  );
};

export default EventRegistrations;
