// import { useState } from "react";
import { useEffect } from "react";
// import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  BlockDes,
  Row,
  Button,
} from "../../../components/Component";
import CheckboxRadio from "../../../components/input/checkbox-radio/checkbox-radio";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import { addAlert } from "../../../utils/alert";
const CreatUpdateModal = ({
  show,
  onClose,
  coupon,
  onSuccess,
  // parentId,
  websitesOption,
}) => {
  console.log("Data", coupon);
  const { isLoading, sendFormRequest, validationErros, resetValidationErrors } =
    useRequest();

  const { inputs, setOne, setFields, reset } = useForm({
    code: "",
    type: "",
    expiry_date: "",
    website: "",
    // is_featured: "",
  });

  useEffect(() => {
    if (coupon) {
      setFields({
        code: coupon.code ?? "",
        type: coupon.type ?? "",
        value: coupon.value ?? "",
        expiry_date: coupon.expiry_date ?? "",
        website: coupon.website ?? "",
        // is_featured: coupon.is_featured ? 1 : 0,
      });
    }
  }, [coupon, setFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const data = new FormData();
      data.append("code", inputs.code);
      data.append("type", inputs.type);
      data.append("value", inputs.value);
      data.append("expiry_date", inputs.expiry_date);
      data.append("website", inputs.website);
      // data.append("is_featured", inputs.is_featured);

      // data.append("parent_id", parentId ?? "");

      if (coupon) {
        data.append("_method", "PUT");
      }

      let response = await sendFormRequest({
        url: coupon
          ? apiRoutes.COUPON_UPDATE(coupon.id)
          : apiRoutes.COUPON_CREATE,
        method: coupon ? "PATCH" : "POST",
        body: data,
      });

      if (response) {
        reset();
        addAlert(
          toast.TYPE.SUCCESS,
          `Coupon has been ${coupon ? "updated" : "added"} successfully`
        );
        onClose();
        onSuccess();
      }
    }
  };
  return (
    <>
      <SimpleBar
        className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any ${
          show ? "content-active" : ""
        }`}
      >
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h5">{coupon ? "Edit" : "Add"}</BlockTitle>
            <BlockDes>
              <p>Add information and {coupon ? "create" : "update"} coupon</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <form onSubmit={onSubmit}>
            <Row className="g-3">
              <Input
                columnConfig={{ sm: 12 }}
                label="Code"
                set={setOne}
                name="code"
                model={inputs.code}
                error={validationErros?.code}
              />

              <Select
                options={[
                  { label: "Fixed", value: "fixed" },
                  { label: "Percent", value: "percent" },
                ]}
                columnConfig={{ sm: 12 }}
                label="Type"
                set={setOne}
                name="type"
                model={inputs.type}
                error={validationErros?.type}
              />

              <Input
                columnConfig={{ sm: 12 }}
                label="Value"
                set={setOne}
                name="value"
                model={inputs.value}
                error={validationErros?.value}
              />

              <Input
                columnConfig={{ sm: 12 }}
                label="Expiry Date"
                type="date"
                set={setOne}
                name="expiry_date"
                model={inputs.expiry_date}
                error={validationErros?.expiry_date}
              />
              <Select
                options={websitesOption}
                columnConfig={{ sm: 12 }}
                label="Website"
                set={setOne}
                name="website"
                model={inputs.website}
                error={validationErros?.website}
              />

              {/* <CheckboxRadio
                options={[
                  {
                    label: "Yes",
                    value: 1,
                  },
                  {
                    label: "No",
                    value: 0,
                  },
                ]}
                columnConfig={{ sm: 12 }}
                label="Is Featured"
                type={"radio"}
                set={setOne}
                name="is_featured"
                model={inputs.is_featured}
                error={validationErros?.is_featured}
              /> */}

              <Col size={{ sm: 12 }}>
                <Button isLoading={isLoading}>
                  {coupon ? "Update" : "Create"}
                </Button>
              </Col>
            </Row>
          </form>
        </Block>
      </SimpleBar>
      {show && (
        <div
          className="toggle-overlay"
          onClick={() => {
            reset();
            resetValidationErrors();
            onClose();
          }}
        ></div>
      )}
    </>
  );
};

export default CreatUpdateModal;
