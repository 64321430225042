import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Col, Icon } from "../../../components/Component";
import Currency from "../../../components/html/Currency";
import useForm from "../../../hooks/use-form";
import {
  myFatoorahRefundDetails,
  paypalRefundDetails,
  round2Decimal,
} from "../../../utils/methods";
import Button from "../../../components/button/Button";
import useRequest from "../../../hooks/use-request";
import Input from "../../../components/input/input/input";
import { useEffect, useState } from "react";
import apiRoutes from "../../../routes/api-routes";
import { addAlert } from "../../../utils/alert";
import { toast } from "react-toastify";
import Textarea from "../../../components/input/textarea/Textarea";
import { PaymentMethods } from "../../../utils/statics";
const RefundModal = ({ orderProduct, onClose, onRefund }) => {
  let refundData = [];
  const [inputError, setInputError] = useState("");
  const { inputs, setOne, reset } = useForm({
    text: "",
    note: "",
  });

  const { isLoading, sendRequest } = useRequest();

  if (orderProduct?.order?.payment_method === PaymentMethods.PAYPAL) {
    refundData = paypalRefundDetails(orderProduct);
  } else if (
    orderProduct?.order?.payment_method === PaymentMethods.MY_FATOORAH
  ) {
    refundData = myFatoorahRefundDetails(orderProduct);
  }

  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/
  const onSubmit = async (e) => {
    e.preventDefault();
    if (inputs.text === "refund") {
      setInputError(null);
      if (!isLoading) {
        let response = await sendRequest({
          url: apiRoutes.ORDER_REFUND(orderProduct?.id),
          method: "POST",
        });

        if (response) {
          addAlert(
            toast.TYPE.SUCCESS,
            "Payment has been refunded successfully"
          );
          reset();
          onRefund();
        }
      }
    } else {
      setInputError("!");
    }
  };
  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return (
    <Modal
      isOpen={!!orderProduct}
      toggle={() => {
        onClose();
      }}
      fullscreen
      size="md"
    >
      <ModalHeader
        toggle={() => {
          reset();
          onClose();
        }}
        close={
          <button
            className="close"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            <Icon name="cross" />
          </button>
        }
      >
        Refund
      </ModalHeader>
      <form onSubmit={onSubmit}>
        <ModalBody>
          {refundData?.map((data, index) => {
            if (refundData?.length - 1 === index) {
              return (
                <div
                  className="flex-between mb-1 mt-2 pt-1"
                  style={{
                    borderTop: "1px solid #c7c7c7",
                    fontWeight: "500",
                  }}
                >
                  <span className="">{data.label}</span>
                  <span className="text-warning">
                    <Currency amount={data.value} />
                  </span>
                </div>
              );
            } else {
              return (
                <div className="flex-between mb-1">
                  <span>{data.label}</span>
                  <span>
                    <Currency amount={data.value} />
                  </span>
                </div>
              );
            }
          })}

          <div
            className="pt-2 mb-4"
            style={{
              borderTop: "5px double #f0f0f0",
            }}
          >
            {orderProduct?.order?.payment_method ===
              PaymentMethods.MY_FATOORAH && (
              <div className="text-muted mb-2" style={{ fontSize: "12px" }}>
                <p className="mb-0">
                  Please note that there will be a refund service charge based
                  on the country of the card used to purchase. Kuwait 0 QAR |
                  GCC countries 2 QAR | Other 10 QAR. It will fail if you try
                  refund amount which is below the refund charge
                </p>
              </div>
            )}
            <Textarea
              set={setOne}
              name="note"
              model={inputs.note}
              label="Note (Optional)"
            />
            <p className="mb-1 text-muted" style={{ marginTop: "-8px" }}>
              Type "refund" to confirm it
            </p>
            <Input
              set={setOne}
              name="text"
              model={inputs.text}
              placeholder={``}
              error={inputError}
            />
          </div>
          <Button isLoading={isLoading} className=" d-block w-100">
            Refund
          </Button>
        </ModalBody>
      </form>
    </Modal>
  );
};

export default RefundModal;
