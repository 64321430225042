import _ from "lodash";
import { capitalizeText } from "../../../utils/methods";

const OrderStatusBadge = ({ status }) => {
  let className = {
    IN_PROGRESS: "blue",
    SHIPPED: "warning",
    DELIVERED: "success",
    CANCELLED: "light",
  };
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return (
    <>
      <span className={`dot bg-${className[status]} d-mb-none`}></span>
      <span
        className={`badge badge-sm badge-dot has-bg badge-${className[status]} d-none d-mb-inline-flex`}
      >
        {capitalizeText(status.toLowerCase())}
      </span>
    </>
  );
};

export default OrderStatusBadge;
