import { Fragment } from "react";
import { Button, Col } from "reactstrap";
import { Icon, TooltipComponent } from "../../Component";
import Image from "../../image/Image";
import styles from "./image.module.css";
const ImageInput = ({
  label,
  error,
  set,
  type,
  model,
  name,
  width,
  height,
  columnConfig,
  removable = true,
  accept,
  ...props
}) => {
  let Wrapper = columnConfig ? Col : Fragment;

  const onImageChange = (e) => {
    console.log(e);
    const imagesArray = e.target.files;
    if (imagesArray?.length > 0) {
      set(name, {
        file: imagesArray[0],
        id: Math.random(),
        url: URL.createObjectURL(imagesArray[0]),
        type: imagesArray[0]?.type,
      });
    }
  };

  const onRemove = () => {
    if (removable) {
      set(name, null);
    }
  };
  return (
    <Wrapper {...columnConfig}>
      <div className={styles.mainContainer}>
        <input
          type={"file"}
          accept={accept ?? "image/*"}
          id={name}
          hidden
          onChange={onImageChange}
        />
        <div
          style={{
            width: width ?? "150px",
            height: height ?? "150px",
          }}
          className={`${styles.imageContainer} ${error && styles.errorImage}`}
        >
          <label
            htmlFor={name}
            className={` ${
              type == "circle"
                ? styles.imageContainerCircle
                : styles.imageContainerRectangle
            }`}
            style={{
              background: model?.type?.startsWith("video") && "#000",
            }}
          >
            {model &&
              (model?.type?.startsWith("video") ? (
                <p style={{ background: "#000" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{
                      width: "40px",
                      color: "#fff",
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                    />
                  </svg>
                  {/* Video Selected: {model.type} */}
                </p>
              ) : (
                <Image src={model.url} alt={"selected profile pic"} />
              ))}
            {!model && <Icon name="plus" className={styles.plusIcon} />}
          </label>

          {model && removable && (
            <Button
              onClick={onRemove}
              type="button"
              className={`btn-round btn-icon ${styles.removebtn}`}
              color="danger"
              size="sm"
            >
              <TooltipComponent
                direction={"right"}
                text={"Remove"}
                id={"name"}
                icon={"minus"}
              ></TooltipComponent>
            </Button>
          )}
        </div>
        <p className=" form-label text-capitalize "> {label}</p>
        {error && <span className={styles.invalidMessage}>{error}</span>}
      </div>
    </Wrapper>
  );
};

export default ImageInput;
