const routes = {
  DASHBOARD_MAIN: "/",

  AUTH_LOGIN: "/account/login",
  AUTH_REGISTER: "/account/register",

  ADMIN_INDEX: "/admins",
  ADMIN_CREATE: "/admins/create",
  ADMIN_EDIT: (id) => `/admins/${id}`,

  CARRIER_INDEX: "/carriers",
  CARRIER_COUNTRIES_INDEX: (carrierId) => `/carriers/${carrierId}/countries`,

  CATEGORY_INDEX: "/categories",
  SUB_CATEGORY_INDEX: (id) => `/categories/${id}`,

  SIZE_GUIDE_INDEX: "/size-guides",

  BANNER_INDEX: (type) => `/banners/${type}`,

  PRODUCT_INDEX: "/products",
  PRODUCT_CREATE: "/products/create",
  PRODUCT_EDIT: (id) => `/products/${id}`,

  USER_SETTINGS_PROFILE: "/user-settings/profile",
  USER_SETTINGS_NOTIFICATION: "/user-settings/notification",
  USER_SETTINGS_ACTIVITIES: "/user-settings/activities",
  USER_SETTINGS_SECURITY: "/user-settings/security",

  APP_SETTINGS_NOTIFICATION_CONFIGURATIONS:
    "/settings/notification-configurations",

  ORDER_INDEX: "/orders",
  ORDER_INVOICE: (orderId) => "/orders/" + orderId,
  ORDER_INVOICE_PRINT: (orderId) => "/orders/" + orderId,

  EVENTS_INDEX: "/events",
  EVENT_REGISTRATIONS: (eventId) => `/events/${eventId}`,

  CUSTOMERS_INDEX: "/customers",

  DIRECT_MESSAGE_INDEX: "/direct-messages",

  NEWSLETTER_SUBSCRIBERS_INDEX: "/newsletter-subscribers",

  COUPON_INDEX: "/coupons",
  COUPON_CREATE: "/create-updates", // Assuming create uses the same endpoint as the index for POST requests

  ERROR: "/error",
};

export default routes;
