import React from "react";
import Head from "../../layouts/dashboard/head/Head";
import { Card, Col, Modal, ModalBody, Row } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../components/Component";
import { useState } from "react";
import useRequest from "../../hooks/use-request";
import Input from "../../components/input/input/input";
import apiRoutes from "../../routes/api-routes";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import useForm from "../../hooks/use-form";
import { useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-reducer";

const Settings = (props) => {
  const [sm, updateSm] = useOutletContext();
  const user = useSelector((store) => store.auth.user);
  const [showModal, setShowModal] = useState(false);
  const [passState, setPassState] = useState(false);
  const {
    isLoading,
    sendRequest,
    validationErros,
    resetValidationErrors,
  } = useRequest();

  const dispatch = useDispatch();

  const {
    isLoading: authIsLoading,
    error: authErrors,
    sendRequest: authSendRequest,
    validationErros: authValidationErros,
    resetValidationErrors: authResetValidationErrors,
  } = useRequest();

  const { setOne, inputs, reset } = useForm({
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  const onChangePassword = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const response = await sendRequest({
        url: apiRoutes.ADMIN_PROFILE_PASSWORD_UPDATE,
        method: "PATCH",
        body: inputs,
      });

      if (response) {
        setShowModal(false);
        reset();
        addAlert(
          toast.TYPE.SUCCESS,
          "Your password changed successfully successfully"
        );
      }
    }
  };

  const onTwoFactorClick = async () => {
    if (user.two_factor_enabled) {
      let { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "It is recommended to protect your account with 2 factor authentication",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Disable",
      });

      if (isConfirmed) {
        disableTwoFactorAuth();
      }
    } else {
      if (await sendOtp()) {
        enableTwoFactorAuth();
      }
    }
  };

  const sendOtp = async () => {
    let response = await authSendRequest({
      url: apiRoutes.OTP_SEND,
      method: "POST",
    });

    if (response) {
      return response.otp_sent;
    }
  };

  const disableTwoFactorAuth = async () => {
    await Swal.fire({
      title: "Type your password",
      input: "password",
      reverseButtons: true,
      inputPlaceholder: "************",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value.length === 0) {
            resolve("Password is required");
          } else {
            resolve();
          }
        });
      },
      showCancelButton: true,
      confirmButtonText: "Disable",
      confirmButtonColor: "#000",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async (password) => {
        try {
          let response = await authSendRequest({
            url: apiRoutes.TWO_FACTOR_DISABLE,
            method: "PATCH",
            body: {
              password: password,
            },
          });

          if (response) {
            dispatch(
              authActions.initialize({
                user: {
                  ...user,
                  two_factor_enabled: false,
                },
              })
            );
            Swal.fire(
              "Verified",
              `Two factor authentication disabled successfully`,
              "success"
            );
          } else if (authErrors) {
            Swal.showValidationMessage(authErrors);
          } else {
            Swal.showValidationMessage(`Invalid password`);
          }
        } catch (error) {
          Swal.showValidationMessage(
            error?.response?.data?.message ?? error.message
          );
        }
      },
    });
  };

  const enableTwoFactorAuth = async () => {
    await Swal.fire({
      title: "Type the passcode send to " + user.phone_number,
      input: "number",
      reverseButtons: true,
      inputAttributes: {
        maxlength: 6,
      },
      inputPlaceholder: "Type recieved 6 Digit OTP",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value.length === 6) {
            resolve();
          } else {
            resolve("You need to type 6 digit OTP");
          }
        });
      },
      showCancelButton: true,
      confirmButtonText: "Enable",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async (code) => {
        try {
          let response = await authSendRequest({
            url: apiRoutes.TWO_FACTOR_ENABLE,
            method: "PATCH",
            body: {
              code: code,
            },
          });

          if (response) {
            dispatch(
              authActions.initialize({
                user: {
                  ...user,
                  two_factor_enabled: true,
                },
              })
            );
            Swal.fire(
              "Verified",
              `Two factor authentication enable successfully`,
              "success"
            );
          } else if (authErrors) {
            Swal.showValidationMessage(authErrors);
          } else {
            Swal.showValidationMessage(`Invalid OTP`);
          }
        } catch (error) {
          Swal.showValidationMessage(
            error?.response?.data?.message ?? error.message
          );
        }
      },
    });
  };

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Security Settings</BlockTitle>
            <BlockDes>
              <p>These settings will help you to keep your account secure.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              color={""}
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            {/* <div className="card-inner">
              <div className="between-center flex-wrap flex-md-nowrap g-3">
                <div className="nk-block-text">
                  <h6>Save my Activity Logs</h6>
                  <p>
                    You can save your all activity logs including unusual
                    activity detected.
                  </p>
                </div>
                <div className="nk-block-actions">
                  <ul className="align-center gx-3">
                    <li className="order-md-last">
                      <div className="custom-control custom-switch mr-n2">
                        <InputSwitch checked id="activity-log" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Change Password</h6>
                  <p>Set a unique password to protect your account.</p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <Button
                        color="primary"
                        onClick={() => setShowModal(true)}
                      >
                        Change Password
                      </Button>
                    </li>
                    <li>
                      <em className="text-soft text-date fs-12px">
                        {/* Last changed: <span>Oct 2, 2019</span> */}
                      </em>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="between-center flex-wrap flex-md-nowrap g-3">
                <div className="nk-block-text">
                  <h6>
                    2 Factor Auth &nbsp;{" "}
                    {user.two_factor_enabled ? (
                      <span className="badge badge-success  ml-0">Enabled</span>
                    ) : (
                      <span className="badge badge-outline-success  ml-0">
                        Recommended
                      </span>
                    )}
                  </h6>
                  <p>
                    Secure your account with 2FA security. When it is activated
                    you will need to enter not only your password, but also a
                    special code using app. You will receive this code to your
                    mobile number.{" "}
                  </p>
                </div>
                <div className="nk-block-actions">
                  <Button
                    color="primary"
                    isLoading={authIsLoading}
                    onClick={onTwoFactorClick}
                  >
                    {user.two_factor_enabled ? "Disable" : "Enable"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Block>

      <Modal
        isOpen={showModal}
        className="modal-dialog-centered"
        size="md"
        toggle={() => {
          resetValidationErrors();
          setShowModal(false);
          reset();
        }}
      >
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              resetValidationErrors();
              reset();
              setShowModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Change Password</h5>
            <div className="tab-content mt-4">
              <form onSubmit={onChangePassword}>
                <Row className="g-4">
                  <Input
                    columnConfig={{ sm: 12 }}
                    name="old_password"
                    set={setOne}
                    model={inputs.old_password}
                    error={validationErros?.old_password}
                    label="old password"
                    type={passState ? "text" : "password"}
                  />
                  <Input
                    columnConfig={{ sm: 12 }}
                    name="password"
                    set={setOne}
                    model={inputs.password}
                    error={validationErros?.password}
                    label="password"
                    type={passState ? "text" : "password"}
                    rightSide={
                      <a
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState(!passState);
                        }}
                        className={`form-icon form-icon-right passcode-switch ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon
                          name="eye"
                          className="passcode-icon icon-show"
                        ></Icon>

                        <Icon
                          name="eye-off"
                          className="passcode-icon icon-hide"
                        ></Icon>
                      </a>
                    }
                  />
                  <Input
                    columnConfig={{ sm: 12 }}
                    name="password_confirmation"
                    set={setOne}
                    model={inputs.password_confirmation}
                    error={validationErros?.password}
                    label="password confirmation"
                    type={passState ? "text" : "password"}
                  />
                  <Col md={6} className="offset-md-3 mt-3">
                    <Button
                      isLoading={isLoading}
                      size="lg"
                      className="btn-block"
                      type="submit"
                      color="primary"
                    >
                      Update Password
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default Settings;
