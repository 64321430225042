import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import Currency from "../../components/html/Currency";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import {
  capitalizeText,
  imageKitLoader,
  searchParamsToObject,
} from "../../utils/methods";
import HeadSection from "../../components/admin-page-components/HeadSection";
import { useSelector } from "react-redux";
import {
  OrderStatus,
  OrderStatusIcons,
  PaymentMethods,
} from "../../utils/statics";
import _ from "lodash";
import OrderStatusBadge from "./partials/order-status-badge";
import PaymentMethodBadge from "./partials/payment-method-badge";
import routes from "../../routes/routes-data";
import RefundModal from "./partials/refund-modal";
import Image from "../../components/image/Image";
import CustomMeasurementModal from "./partials/custom-measurement-modal";

const OrderProductsIndex = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderProducts, setOrderProducts] = useState([]);
  const [refundOrderProduct, setRefundOrderProduct] = useState(null);
  const [measurement, setMeasurement] = useState(null);

  const [paginationData, setPaginationData] = useState([]);

  const { isLoading, sendRequest, error } = useRequest();
  const { sendRequest: sendRequestStatus, isLoading: statusIsLoading } =
    useRequest();

  let location = useLocation();

  let websitesOption = useSelector(
    (store) => store.auth.accessibleWebsites
  ).map((web) => ({
    label: capitalizeText(web),
    value: web,
  }));

  const getOrderProducts = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.ORDER_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response) {
      setOrderProducts(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onStatusClick = async (orderProductId, status) => {
    if (!statusIsLoading) {
      let response = await sendRequestStatus({
        url: apiRoutes.ORDER_UPDATE_STATUS(orderProductId),
        method: "PATCH",
        body: {
          status: status,
        },
      });
      if (response) {
        setOrderProducts((prev) =>
          prev?.map((orderProduct) => {
            if (orderProductId == orderProduct.id) {
              orderProduct.order_status = status;
              return orderProduct;
            } else {
              return orderProduct;
            }
          })
        );
      }
    }
  };

  // const onDeleteConfirm = async (id) => {
  //   if (!isLoading) {
  //     let response = await sendRequest({
  //       url: apiRoutes.ORDER_DELETE(id),
  //       method: "DELETE",
  //     });
  //     if (response) {
  //       addAlert(toast.TYPE.SUCCESS, "Order has been deleted successfully");
  //       getOrderProducts();
  //     }
  //   }
  //   return true;
  // };
  // const onDeleteClick = (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: `You won't be able to revert this!`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       onDeleteConfirm(id);
  //     }
  //   });
  // };

  useEffect(() => {
    setOrderProducts([]);
  }, [location.pathname]);
  useEffect(() => {
    getOrderProducts();
  }, [getOrderProducts, searchParams]);

  return (
    <>
      <CustomMeasurementModal
        onClose={() => setMeasurement(null)}
        measurement={measurement}
      />
      <RefundModal
        orderProduct={refundOrderProduct}
        onClose={() => {
          setRefundOrderProduct(null);
        }}
        onRefund={() => {
          setRefundOrderProduct(null);
          getOrderProducts();
        }}
      />
      <Head title="Orders"></Head>
      <Content>
        <HeadSection title={"Orders"} />
        {/* ? = SUB CATEGORIES PAGE VIEW | : = MAIN CATEGORIES PAGE VIEW  */}
        {
          <PageTable
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            searchPlaceholder={"Search by order name, id, or website"}
            title={`${
              searchParams.get("website")
                ? capitalizeText(searchParams.get("website"))
                : "All"
            } Orders`}
            isLoading={isLoading}
            error={error}
            headers={[
              {
                value: "order",
                props: {
                  className: "w-10px text-center",
                },
              },
              "",
              "payment method",
              "shipping",
              "subtotal",
              "status",
              "website",
            ]}
            actions={true}
            body={orderProducts?.map((orderProduct) => [
              {
                value: "#" + orderProduct.order?.id,
                props: {
                  className: "w-10px text-center",
                },
              },
              <span className="tb-product">
                <Image
                  src={imageKitLoader(
                    orderProduct.product?.main_image?.path,
                    48
                  )}
                  alt="order"
                  className="thumb"
                  style={{
                    width: "48px",
                    height: "48px",
                    objectFit: "cover",
                  }}
                />
                <div>
                  <p className="title text-capitalize mb-0">
                    {orderProduct.product?.name ?? "(Product not found)"}
                  </p>
                  <p className="mb-0">
                    {orderProduct?.product_variant?.product_option_values?.map(
                      (optVal, index) => (
                        <span key={optVal.value}>
                          {optVal.value}
                          {index !=
                            orderProduct?.product_variant?.product_option_values
                              ?.length -
                              1 && <span className="px-2">|</span>}
                        </span>
                      )
                    )}
                    {orderProduct?.custom_measurement && (
                      <>
                        {orderProduct?.product_variant?.product_option_values
                          ?.length > 0 && " |  "}
                        <span
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          className="d-inline   text-decoration-underline   text-primary"
                          onClick={() =>
                            setMeasurement(orderProduct.custom_measurement)
                          }
                        >
                          Measurement
                        </span>
                      </>
                    )}
                  </p>
                  <p>
                    <Currency
                      amount={orderProduct.subtotal / orderProduct.quantity}
                    />{" "}
                    x {orderProduct.quantity}
                  </p>
                </div>
              </span>,
              <PaymentMethodBadge
                method={orderProduct.order?.payment_method}
              />,
              orderProduct.shipping,
              orderProduct.subtotal,
              <OrderStatusBadge status={orderProduct.order_status} />,
              capitalizeText(orderProduct.order?.website),
              [
                ...Object.values(OrderStatus)
                  ?.filter((status) => status !== orderProduct.order_status)
                  ?.map((status) => {
                    //only allow cancel state change for cod payment becuase others need refund to be cancelled
                    if (
                      (status !== OrderStatus.CANCELLED ||
                        orderProduct.order.payment_method ==
                          PaymentMethods.CASH_ON_DELIVERY) &&
                      (orderProduct.order_status !== OrderStatus.CANCELLED ||
                        orderProduct.order.payment_method ==
                          PaymentMethods.CASH_ON_DELIVERY)
                    ) {
                      return {
                        dropdown: (
                          <DropdownItem
                            key={"order_" + orderProduct.id + "_" + status}
                            tag="a"
                            href="#"
                            onClick={() =>
                              onStatusClick(orderProduct.id, status)
                            }
                          >
                            <Icon name={OrderStatusIcons[status]}></Icon>
                            <span>
                              Mark as {_.capitalize(status.replace("_", " "))}
                            </span>
                          </DropdownItem>
                        ),
                        tooltip: (
                          <li
                            key={"order_" + orderProduct.id + "_" + status}
                            className="nk-tb-action-hidden"
                            onClick={() =>
                              onStatusClick(orderProduct.id, status)
                            }
                          >
                            <TooltipComponent
                              tag="a"
                              containerClassName="btn btn-trigger btn-icon"
                              id={"order_" + orderProduct.id + "_" + status}
                              icon={OrderStatusIcons[status]}
                              direction="top"
                              text={`Mark as ${_.capitalize(
                                status.replace("_", " ")
                              )}`}
                            />
                          </li>
                        ),
                      };
                    } else {
                      return {};
                    }
                  }),
                orderProduct.order?.payment_method !==
                  PaymentMethods.CASH_ON_DELIVERY &&
                orderProduct.order_status !== OrderStatus.CANCELLED
                  ? {
                      dropdown: (
                        <DropdownItem
                          key={"order_" + orderProduct.id + "_refund"}
                          tag="a"
                          href="#"
                          onClick={() => setRefundOrderProduct(orderProduct)}
                        >
                          <Icon name={"money"}></Icon>
                          <span>Refund & Cancel</span>
                        </DropdownItem>
                      ),
                      tooltip: (
                        <li
                          onClick={() => setRefundOrderProduct(orderProduct)}
                          key={"order_" + orderProduct.id + "_refund"}
                          className="nk-tb-action-hidden"
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={"order_" + orderProduct.id + "_refund"}
                            icon={"money"}
                            direction="top"
                            text={`Refund & Cancel`}
                          />
                        </li>
                      ),
                    }
                  : {},
                {
                  dropdown: (
                    <DropdownItem
                      tag={Link}
                      to={routes.ORDER_INVOICE(orderProduct.order.id)}
                    >
                      <Icon name="printer"></Icon>
                      <span>View Invoice</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li className="nk-tb-action-hidden">
                      <TooltipComponent
                        tag={Link}
                        to={routes.ORDER_INVOICE(orderProduct.order.id)}
                        containerClassName="btn btn-trigger btn-icon"
                        id={"invoice_" + orderProduct.id}
                        icon={"printer"}
                        direction="top"
                        text={`View Invoice`}
                      />
                    </li>
                  ),
                },
              ],
            ])}
            paginationData={paginationData}
            filterOptions={[
              {
                label: "wesbite",
                name: "website",
                options: [
                  {
                    label: "all",
                    value: "",
                  },
                  ...websitesOption,
                ],
              },
            ]}
          />
        }
      </Content>
    </>
  );
};

export default OrderProductsIndex;
