import { imageKitLoader } from "../../utils/methods";

const Image = (props) => {
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/

  return <img onError={(e) => (e.target.src = "/upload.gif")} {...props} />;
};

export default Image;
