import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, DropdownItem, Tooltip } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import {
  searchParamsToObject,
  capitalizeText,
  imageKitLoader,
} from "../../utils/methods";
import CreatUpdateModal from "./partials/create-update-modal";
import HeadSection from "../../components/admin-page-components/HeadSection";
import Button from "../../components/button/Button";
import Swal from "sweetalert2";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import routes from "../../routes/routes-data";
import { useSelector } from "react-redux";
import Image from "../../components/image/Image";
import { BannerTypes } from "../../utils/statics";

const fallbackSrc = "/fallback-image.png";

const BannersIndex = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { type } = useParams();
  const navigate = useNavigate();

  const [banners, setBanners] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();

  let location = useLocation();

  let websiteOptions = useSelector(
    (store) => store.auth.accessibleWebsites
  ).map((web) => ({
    label: web.toLowerCase(),
    value: web,
  }));

  const isSlot = type === BannerTypes.SLOT;
  const getBanners = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.BANNER_INDEX(type),
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response) {
      setBanners(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.BANNER_DELETE(type, id),
        method: "DELETE",
      });
      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Banner has been deleted successfully");
        getBanners();
      }
    }
    return true;
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  useEffect(() => {
    setBanners([]);
  }, [location.pathname]);

  useEffect(() => {
    getBanners();
  }, [getBanners, searchParams, type]);

  let headers = ["banner", "website"];
  isSlot && headers.push("Reference");
  return (
    <>
      <Head title="Banners"></Head>
      <Content>
        <HeadSection
          title={"Banners"}
          right={
            <Button
              className="d-inline-flex"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              <Icon name="plus"></Icon>
            </Button>
          }
        />

        {
          <PageTable
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            searchPlaceholder={"Search by banner name, id"}
            title={isSlot ? "Banner Slots" : "Main Banners"}
            isLoading={isLoading}
            error={error}
            headers={headers}
            actions={true}
            body={banners?.map((banner) => {
              let mediaPath = banner.image?.path;
              // general column
              let row = [
                <span className="tb-product">
                  {mediaPath.endsWith("mp4") || mediaPath.endsWith("flv") ? (
                    <div
                      className="thumb d-flex align-items-center justify-content-center"
                      style={{
                        width: "100px",
                        height: "60px",
                        objectFit: "cover",
                        background: "#000",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        style={{
                          width: "20",
                          color: "#fff",
                        }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <Image
                      src={
                        banner.image?.path
                          ? imageKitLoader(banner.image?.path, 100, 60)
                          : fallbackSrc
                      }
                      alt="product"
                      className="thumb"
                      style={{
                        width: "100px",
                        height: "60px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <div>
                    <p className=" text-capitalize" style={{ marginBottom: 0 }}>
                      {banner.secondary_title}
                    </p>
                    <p className="title text-capitalize">
                      {banner.primary_title}
                    </p>
                  </div>
                </span>,
                capitalizeText(banner.website),
              ];

              // Column  of slot banner
              isSlot && row.push(banner.place);

              // Action column
              row.push([
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => {
                        setSelectedBanner(banner);
                        setShowModal(true);
                      }}
                    >
                      <Icon name="edit"></Icon>
                      <span>Edit</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => {
                        setSelectedBanner(banner);
                        setShowModal(true);
                      }}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"edit" + banner.id}
                        icon="edit"
                        direction="top"
                        text="Edit Banner"
                      />
                    </li>
                  ),
                },
                !isSlot && {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => onDeleteClick(banner.id)}
                    >
                      <Icon name="trash-empty"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => onDeleteClick(banner.id)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"delete" + banner.id}
                        icon="trash-empty"
                        direction="top"
                        text="Delete Banner"
                      />
                    </li>
                  ),
                },
              ]);
              return row;
            })}
            paginationData={paginationData}
            filterOptions={[
              {
                label: "website",
                name: "website",
                options: [
                  {
                    label: "all",
                    value: "",
                  },
                  ...websiteOptions,
                ],
              },
            ]}
          />
        }
      </Content>
      <CreatUpdateModal
        type={type}
        banner={selectedBanner}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedBanner(null);
        }}
        onSuccess={() => getBanners()}
        websiteOptions={websiteOptions}
      />
    </>
  );
};

export default BannersIndex;
