import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SimpleBar from "simplebar-react";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  BlockDes,
  Row,
  Button,
  OverlineTitle,
  Icon,
} from "../../../components/Component";
import CheckboxRadio from "../../../components/input/checkbox-radio/checkbox-radio";
import ImageInput from "../../../components/input/image/image";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import Textarea from "../../../components/input/textarea/Textarea";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import { addAlert } from "../../../utils/alert";
import { capitalizeText } from "../../../utils/methods";
import { EventRegistrationStatus } from "../../../utils/statics";
const CreatUpdateModal = ({
  show,
  onClose,
  event,
  onSuccess,
  websiteOptions,
}) => {
  const { isLoading, sendFormRequest, validationErros, resetValidationErrors } =
    useRequest();

  const { inputs, setOne, setFields, reset } = useForm({
    image: "",
    name: "",
    registration_status: "",
    registration_cost: "",
    starting_at: "",
    website: "",
    description: "",
  });

  useEffect(() => {
    if (event) {
      setFields({
        image: event.image
          ? {
              id: event.image?.id,
              file: null,
              url: event.image?.access_path,
            }
          : null,
        website: event.website ?? "",
        name: event.name ?? "",
        registration_status: event.registration_status ?? "",
        registration_cost: event.registration_cost ?? "",
        starting_at: event.starting_at ?? "",
        description: event.description ?? "",
      });
    }
  }, [event, setFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const data = new FormData();
      data.append("name", inputs.name);
      data.append("registration_status", inputs.registration_status);
      data.append("registration_cost", inputs.registration_cost);
      data.append("starting_at", inputs.starting_at);
      data.append("description", inputs.description);
      data.append("website", inputs.website);
      data.append("image", inputs.image?.file ?? "");
      if (event) {
        data.append("_method", "PATCH");
      }

      let response = await sendFormRequest({
        url: event ? apiRoutes.EVENT_UPDATE(event.id) : apiRoutes.EVENT_CREATE,
        method: event ? "PATCH" : "POST",
        body: data,
      });

      if (response) {
        reset();
        addAlert(
          toast.TYPE.SUCCESS,
          `Event has been ${event ? "updated" : "added"} successfully`
        );
        onClose();
        onSuccess();
      }
    }
  };
  return (
    <>
      <Modal
        isOpen={show}
        toggle={() => {
          reset();
          resetValidationErrors();
          onClose();
        }}
        fullscreen
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            reset();
            resetValidationErrors();
            onClose();
          }}
          close={
            <button
              className="close"
              onClick={() => {
                reset();
                resetValidationErrors();
                onClose();
              }}
            >
              <Icon name="cross" />
            </button>
          }
        >
          {event ? "Edit" : "Add"} Event
        </ModalHeader>

        <form onSubmit={onSubmit}>
          <ModalBody>
            <Row className="g-3">
              <ImageInput
                columnConfig={{ sm: 12 }}
                label="Event Poster"
                set={(key, value) => {
                  setOne(key, value);
                }}
                name="image"
                model={inputs.image}
                error={validationErros?.image}
                width="180px"
                height="220px"
                removable={false}
              />

              <Input
                columnConfig={{ sm: 6 }}
                label="name"
                set={setOne}
                name="name"
                model={inputs.name}
                error={validationErros?.name}
              />
              <Select
                options={websiteOptions}
                columnConfig={{ sm: 6 }}
                label="Website"
                set={setOne}
                name="website"
                model={inputs.website}
                error={validationErros?.website}
              />
              <Select
                options={Object.values(EventRegistrationStatus)?.map((val) => ({
                  label: capitalizeText(val),
                  value: val,
                }))}
                columnConfig={{ sm: 6 }}
                label="registration status"
                set={setOne}
                name="registration_status"
                model={inputs.registration_status}
                error={validationErros?.registration_status}
              />
              <Input
                columnConfig={{ sm: 6 }}
                label="fees"
                set={setOne}
                name="registration_cost"
                model={inputs.registration_cost}
                error={validationErros?.registration_cost}
              />

              <Input
                columnConfig={{ sm: 12 }}
                label="starting date"
                set={setOne}
                type="datetime-local"
                name="starting_at"
                model={inputs.starting_at}
                error={validationErros?.starting_at}
              />
              <Textarea
                columnConfig={{ sm: 12 }}
                label="Description (optional)"
                set={setOne}
                name="description"
                model={inputs.description}
                error={validationErros?.description}
              />
            </Row>
          </ModalBody>
          <ModalFooter className="bg-light">
            <Button isLoading={isLoading}>{event ? "Update" : "Create"}</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default CreatUpdateModal;
