import { useEffect } from "react";
import { toast } from "react-toastify";
import { Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  BlockDes,
  Row,
  Button,
} from "../../../components/Component";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import { addAlert } from "../../../utils/alert";
import { capitalizeText } from "../../../utils/methods";
import { Websites } from "../../../utils/statics";

const CreatUpdateModal = ({
  show,
  onClose,
  notificationConfiguration,
  onSuccess,
}) => {
  const { isLoading, sendRequest, validationErros, resetValidationErrors } =
    useRequest();
  const { inputs, setOne, setFields, reset } = useForm({
    email: "",
    phone_number: "",
    websites: [],
  });

  let websitesOptions = Object.values(Websites).map((web) => ({
    label: capitalizeText(web),
    value: web,
  }));
  useEffect(() => {
    if (notificationConfiguration) {
      setFields({
        email: notificationConfiguration.email,
        phone_number: notificationConfiguration.phone_number,
        websites: notificationConfiguration.websites.map((w) => ({
          label: capitalizeText(w),
          value: w,
        })),
      });
    }
  }, [notificationConfiguration, setFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      let response = await sendRequest({
        url: notificationConfiguration
          ? apiRoutes.NOTIFICATION_CONFIGURATION_UPDATE(
              notificationConfiguration.id
            )
          : apiRoutes.NOTIFICATION_CONFIGURATION_CREATE,
        method: notificationConfiguration ? "PATCH" : "POST",
        body: {
          ...inputs,
          websites: inputs.websites.map((w) => w.value),
        },
      });

      if (response) {
        reset();
        resetValidationErrors();
        addAlert(
          toast.TYPE.SUCCESS,
          `Notification Email has been ${
            notificationConfiguration ? "updated" : "added"
          } successfully`
        );
        onClose();
        onSuccess();
      }
    }
  };
  return (
    <>
      <SimpleBar
        className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any ${
          show ? "content-active" : ""
        }`}
      >
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h5">
              {notificationConfiguration ? "Edit" : "Add"} Notification Email
            </BlockTitle>
            <BlockDes>
              <p>Add information and create notification email</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <form onSubmit={onSubmit}>
            <Row className="g-3">
              <Input
                columnConfig={{ sm: 12 }}
                label="email"
                set={setOne}
                name="email"
                model={inputs.email}
                error={validationErros?.email}
              />
              <Input
                columnConfig={{ sm: 12 }}
                label="phone number"
                set={setOne}
                name="phone_number"
                model={inputs.phone_number}
                error={validationErros?.phone_number}
              />
              <Select
                options={websitesOptions}
                multiple
                columnConfig={{ sm: 12 }}
                label="Websites"
                set={setOne}
                name="websites"
                model={inputs.websites}
                error={validationErros?.websites}
              />
              <Col>
                <Button isLoading={isLoading}>
                  {notificationConfiguration ? "Update" : "Create"}
                </Button>
              </Col>
            </Row>
          </form>
        </Block>
      </SimpleBar>
      {show && (
        <div
          className="toggle-overlay"
          onClick={() => {
            reset();
            resetValidationErrors();
            onClose();
          }}
        ></div>
      )}
    </>
  );
};

export default CreatUpdateModal;
