import { Alert, Card, Col, Form, Row, UncontrolledTooltip } from "reactstrap";
import {
  Button,
  Icon,
  PreviewCard,
  TooltipComponent,
} from "../../../components/Component";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import useForm from "../../../hooks/use-form";
import { ProductOptionTypes } from "../../../utils/statics";

const OptionCard = ({
  validationErros,
  setIsOptionUpdated,
  index,
  inputs,
  setOne,
  setFields,
}) => {
  const OnRemoveValue = (id) => {
    setIsOptionUpdated(true);
    setFields((prev) => {
      let newObj = { ...prev };
      newObj.options = newObj.options.map((op) => {
        if (op.id != inputs.options[index].id) {
          return op;
        } else {
          return {
            ...op,
            values: op.values.filter((val) => val.id != id),
          };
        }
      });
      return newObj;
    });
  };
  const onOptionValueAdd = () => {
    setFields((prev) => {
      let newObj = { ...prev };
      newObj.options = newObj.options.map((op) => {
        if (op.id != inputs.options[index].id) {
          return op;
        } else {
          return {
            ...op,
            values: [
              ...op.values,
              {
                id: Math.random(),
                value: "",
                color: "#000000",
              },
            ],
          };
        }
      });
      return newObj;
    });
    setIsOptionUpdated(true);
  };
  return (
    <Col sm={12}>
      <PreviewCard>
        <button
          type="button"
          className="close"
          onClick={() => {
            setFields((prev) => {
              let newObj = { ...prev };
              newObj.options = newObj.options.filter(
                (op) => op.id !== inputs.options[index].id
              );
              return newObj;
            });
            setIsOptionUpdated(true);
          }}
        >
          <Icon name="cross" />
        </button>
        <Row className="g-4">
          <Input
            columnConfig={{ sm: 6 }}
            label="name"
            set={setOne}
            name={`options[${index}].name`}
            model={inputs.options[index].name}
            error={validationErros?.[`options.${index}.name`]}
          />
          <Select
            options={[
              {
                label: "color",
                value: ProductOptionTypes.COLOR,
              },
              {
                label: "text",
                value: ProductOptionTypes.TEXT,
              },
            ]}
            columnConfig={{ sm: 6 }}
            label="type"
            set={setOne}
            name={`options[${index}].type`}
            model={inputs.options[index].type}
            error={validationErros?.[`options.${index}.type`]}
          />

          <Col sm={12}>
            <table className="table table-ulogs ">
              <thead className="thead-light">
                <tr>
                  <th className="tb-col-os" style={{ verticalAlign: "middle" }}>
                    <span className="overline-title">Value</span>
                  </th>

                  {inputs.options[index]?.type === ProductOptionTypes.COLOR && (
                    <th
                      className="tb-col-ip"
                      style={{ verticalAlign: "middle" }}
                    >
                      <span className="overline-title">Color</span>
                    </th>
                  )}
                  <th
                    className="tb-col-action"
                    style={{ verticalAlign: "middle" }}
                  >
                    <span className="overline-title">
                      {" "}
                      <Button
                        onClick={onOptionValueAdd}
                        size="sm"
                        className={"m-auto btn-icon btn-round"}
                        type="button"
                        color="outline-light"
                      >
                        <Icon name={"plus"} />
                      </Button>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {inputs.options[index].values?.map((item, idx) => {
                  return (
                    <tr key={item.id}>
                      <td className="tb-col-ip" style={{ paddingLeft: 0 }}>
                        <Input
                          set={setOne}
                          placeholder="Type the value"
                          name={`options[${index}].values[${idx}].value`}
                          model={inputs.options[index].values[idx].value}
                          error={
                            validationErros?.[
                              `options.${index}.values.${idx}.value`
                            ]
                          }
                        />
                      </td>
                      {inputs.options[index].type ===
                        ProductOptionTypes.COLOR && (
                        <td className="tb-col-ip">
                          <Input
                            type="color"
                            set={setOne}
                            name={`options[${index}].values[${idx}].color`}
                            model={inputs.options[index].values[idx].color}
                            error={
                              validationErros?.[
                                `options.${index}.values.${idx}.color`
                              ]
                            }
                          />
                        </td>
                      )}
                      <td className="tb-col-action align-middle">
                        <a
                          href="#delete"
                          onClick={() => OnRemoveValue(item.id)}
                          className="link-cross mr-sm-n1"
                        >
                          <Icon name="cross"></Icon>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {inputs.options[index].values?.length === 0 &&
              !validationErros?.[`options.${index}.values`] && (
                <div className="">
                  <Alert color="light" className="alert-icon text-center">
                    No values added so far :(
                  </Alert>
                </div>
              )}
            {validationErros?.[`options.${index}.values`] &&
              inputs.options[index].values?.length === 0 && (
                <div className="">
                  <Alert color="danger" className="alert-icon text-center">
                    {validationErros?.[`options.${index}.values`]}
                  </Alert>
                </div>
              )}
          </Col>
        </Row>
      </PreviewCard>
    </Col>
  );
};

export default OptionCard;
