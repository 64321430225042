import axios from "axios";
import { useCallback } from "react";
import { useEffect } from "react";
// import { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import routes from "../routes/routes-data";
import { authActions } from "../store/auth-reducer";
import { addAlert } from "../utils/alert";
const useRequest = ({ errorHandle = true } = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [validationErros, setValidationErros] = useState(null);
  const token = useSelector((store) => store.auth.token);
  // let navigate = useNavigate();
  // let location = useLocation();
  const dispatch = useDispatch();

  const onError = useCallback(
    (error) => {
      if (errorHandle) {
        if (error.response?.status) {
          if (error.response.status === 422) {
            setValidationErros(error.response?.data?.errors);
          } else if (error.response.status === 404) {
            setError("Data is you are looking for is not found");
          } else if (error.response.status === 401) {
            dispatch(authActions.onLogout());
          } else {
            //production
            // setError("Something went wrong, Please try again");

            //development
            setError(error?.response?.data?.message ?? error.message);
          }
        } else {
          setError(error.message);
        }
      }
    },
    [dispatch, errorHandle]
  );

  const sendRequest = useCallback(
    async (config) => {
      try {
        setError(null);
        setValidationErros(null);
        setIsLoading(true);

        let response = await axios.request({
          method: config.method ?? "GET",
          url: process.env.REACT_APP_BACKEND_API + config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: config.token
              ? "Bearer " + config.token
              : token && "Bearer " + token,
            ...config.headers,
          },
          data: config.body,
          params: config.queryParams,
        });
        let { data, success, message } = response.data;
        if (success === true) {
          return data ?? true;
        } else {
          setError(message);
          return null;
        }
      } catch (error) {
        onError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [onError, token]
  );

  const resetValidationErrors = () => {
    setValidationErros(null);
  };

  const sendFormRequest = async (config) => {
    try {
      setError(null);
      setValidationErros(null);
      setIsLoading(true);

      let response = await axios.postForm(
        process.env.REACT_APP_BACKEND_API + config.url,
        config.body,
        {
          headers: {
            Accept: "application/json",
            Authorization: config.token
              ? "Bearer " + config.token
              : token && "Bearer " + token,
            ...config.headers,
          },
        }
      );

      let { data, success, message } = response.data;
      if (success === true) {
        return data ?? true;
      } else {
        setError(message);
        return null;
      }
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error && errorHandle) {
      addAlert(toast.TYPE.ERROR, error);
    }
  }, [errorHandle, error]);
  return {
    isLoading,
    error,
    validationErros,
    sendRequest,
    resetValidationErrors,
    sendFormRequest,
  };
};
export default useRequest;
