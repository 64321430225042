import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "reactstrap";
import {
  BlockBetween,
  BlockHeadContent,
  Button,
} from "../../../components/Component";
import useRequest from "../../../hooks/use-request";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import CheckboxRadio from "../../../components/input/checkbox-radio/checkbox-radio";
import apiRoutes from "../../../routes/api-routes";
import { useSelector } from "react-redux";
import useForm from "../../../hooks/use-form"; // Corrected this import

const Details = ({
  stepState: { hasNext, next },
  onUnmount,
  couponId,
  fields,
  ...props
}) => {
  const { isLoading, validationErrors, sendFormRequest } = useRequest();
  const { inputs, setOne, setFields } = useForm({});
  const [websitesOptions, setWebsitesOptions] = useState([]);

  // Fetch accessible websites from the Redux store
  let websites = useSelector((store) => store.auth.accessibleWebsites);

  useEffect(() => {
    setWebsitesOptions(
      websites.map((web) => ({
        label: web.toLowerCase(),
        value: web,
      }))
    );
  }, [websites]);

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    // Append form data
    data.append("code", inputs.code);
    data.append("type", inputs.type);
    data.append("value", inputs.value);
    data.append("expiry_date", inputs.expiry_date);
    data.append("website", inputs.website);
    data.append("is_featured", inputs.is_featured ? 1 : 0);

    if (!isLoading) {
      const url = couponId
        ? apiRoutes.COUPON_UPDATE(couponId) // Update if couponId exists
        : apiRoutes.COUPON_CREATE; // Create if couponId is null
      const method = couponId ? "PUT" : "POST"; // PUT for update, POST for create

      let response = await sendFormRequest({
        url: url,
        method: method,
        body: data,
      });

      if (response) {
        onUnmount(inputs); // Handle after form submission
      }
    }
  };

  useEffect(() => {
    setFields(fields);
  }, [fields, setFields]);

  return (
    <div className="">
      <Form onSubmit={onFormSubmit}>
        <Row className="g-4">
          <Input
            columnConfig={{ sm: 12 }}
            label="Code"
            set={setOne}
            name="code"
            model={inputs.code}
            error={validationErrors?.code}
          />

          <Select
            options={[
              { label: "Fixed", value: "fixed" },
              { label: "Percent", value: "percent" },
            ]}
            columnConfig={{ sm: 12 }}
            label="Type"
            set={setOne}
            name="type"
            model={inputs.type}
            error={validationErrors?.type}
          />

          <Input
            columnConfig={{ sm: 12 }}
            label="Value"
            set={setOne}
            name="value"
            model={inputs.value}
            error={validationErrors?.value}
          />

          <Input
            columnConfig={{ sm: 12 }}
            label="Expiry Date"
            type="date"
            set={setOne}
            name="expiry_date"
            model={inputs.expiry_date}
            error={validationErrors?.expiry_date}
          />

          <Select
            options={websitesOptions}
            columnConfig={{ sm: 12 }}
            label="Website"
            set={setOne}
            name="website"
            model={inputs.website}
            error={validationErrors?.website}
          />

          <CheckboxRadio
            className={"mb-4"}
            options={[
              {
                label: "Is Featured",
                value: 1,
              },
            ]}
            columnConfig={{ sm: 12 }}
            type={"checkbox"}
            set={setOne}
            name="is_featured"
            model={inputs.is_featured}
            error={validationErrors?.is_featured}
          />

          <Col sm={12} className="mt-3">
            <BlockBetween>
              <BlockHeadContent></BlockHeadContent>
              <BlockHeadContent>
                <Button
                  isLoading={isLoading}
                  size="lg"
                  className={"m-auto d-block"}
                  type="submit"
                  color="primary"
                >
                  {couponId ? "Update" : "Create"}
                </Button>
              </BlockHeadContent>
            </BlockBetween>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Details;
