import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import { searchParamsToObject } from "../../utils/methods";
import HeadSection from "../../components/admin-page-components/HeadSection";

import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const NewsletterSubscribersIndex = (props) => {
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [newsletterSubscribers, setNewsletterSubscribers] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();

  const getNewsletterSubscribers = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.NEWSLETTER_SUBSCRIBERS_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response?.data) {
      setNewsletterSubscribers(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page !== 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.NEWSLETTER_SUBSCRIBERS_DELETE(id),
        method: "DELETE",
      });
      if (response) {
        addAlert(
          toast.TYPE.SUCCESS,
          "Subscriber has been deleted successfully"
        );
        getNewsletterSubscribers();
      }
    }
    return true;
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };
  useEffect(() => {
    getNewsletterSubscribers();
  }, [getNewsletterSubscribers, searchParams]);

  return (
    <>
      <Head title="Subscribers"></Head>
      <Content>
        <HeadSection title={"Subscribers"} />
        <PageTable
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchPlaceholder={"Search by newsletter subscriber email, id"}
          title="All Subscribers"
          isLoading={isLoading}
          error={error}
          headers={["id", "email"]}
          actions={true}
          body={newsletterSubscribers?.map((newsletterSubscriber) => {
            return [
              newsletterSubscriber.id,
              newsletterSubscriber.email,
              [
                {
                  dropdown: (
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={() => onDeleteClick(newsletterSubscriber.id)}
                    >
                      <Icon name="trash-empty"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  ),
                  tooltip: (
                    <li
                      className="nk-tb-action-hidden"
                      onClick={() => onDeleteClick(newsletterSubscriber.id)}
                    >
                      <TooltipComponent
                        tag="a"
                        containerClassName="btn btn-trigger btn-icon"
                        id={"delete" + newsletterSubscriber.id}
                        icon="trash-empty"
                        direction="top"
                        text="Delete Subscriber"
                      />
                    </li>
                  ),
                },
              ],
            ];
          })}
          paginationData={paginationData}
        />
      </Content>
    </>
  );
};

export default NewsletterSubscribersIndex;
