import { Link } from "react-router-dom";
import { Icon } from "../../../components/Component";
import Currency from "../../../components/html/Currency";
import Button from "../../../components/button/Button";
import LogoDark from "../../../images/logo-dark.png";
import routes from "../../../routes/routes-data";
import { AddressTypes } from "../../../utils/statics";
import PaymentMethodBadge from "./payment-method-badge";
import { capitalizeText } from "../../../utils/methods";

const Invoice = ({ order, onPrintClick }) => {
  /*-------------------------------------------
            Functions
--------------------------------------------*/

  /*-------------------------------------------
            Async Functions
--------------------------------------------*/

  /*-------------------------------------------
            Lifecycle Hooks
--------------------------------------------*/

  /*-------------------------------------------
            HTML
--------------------------------------------*/
  let totalSubtotal = order?.order_products?.reduce((sum, orderProduct) => {
    return sum + parseFloat(orderProduct.subtotal);
  }, 0);

  let totalShipping = order?.order_products?.reduce((sum, orderProduct) => {
    return sum + parseFloat(orderProduct.shipping);
  }, 0);
  return (
    <div className="invoice" id="invoice-printable">
      <div className="invoice-action">
        <Button
          onClick={onPrintClick}
          size="lg"
          color="primary"
          outline
          className="btn-icon btn-white btn-dim "
          id="print-btn"
        >
          <Icon name="printer-fill"></Icon>
        </Button>
      </div>
      <div className="invoice-wrap">
        <div className="invoice-brand text-center">
          <img src={LogoDark} alt="" />
        </div>

        <div className="invoice-head">
          <div className="invoice-contact">
            <span className="overline-title">Invoice To</span>
            <div className="invoice-contact-info">
              <h4 className="title">
                {`${order.customer?.first_name} ${order.customer?.last_name}` ??
                  "(Customer Not Found)"}
              </h4>
              <ul className="list-plain">
                <li>
                  <Icon name="map-pin-fill"></Icon>
                  <span>
                    {`${order.shipping_address?.first_name} ${order.shipping_address?.last_name}`}
                    <br />
                    {order.shipping_address?.address_1},{" "}
                    {order.shipping_address?.address_2}
                    <br />
                    {order.shipping_address?.region_1},{" "}
                    {order.shipping_address?.region_2}
                    <br />
                    {order.shipping_address?.country_name}
                  </span>
                </li>
                <li>
                  <Icon name="call-fill"></Icon>
                  <span>
                    +{order.shipping_address?.phone_code}{" "}
                    {order.shipping_address?.phone_number}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="invoice-desc"
            style={{
              width: "auto",
            }}
          >
            <h3 className="title">Invoice</h3>
            <ul className="list-plain">
              <li className="invoice-id">
                <span>Order ID</span>:<span>{order.id}</span>
              </li>
              <li className="invoice-date">
                <span>Date</span>:<span>{order.created_at}</span>
              </li>
              <li className="invoice-date">
                <span> Method</span>:
                <span>{capitalizeText(order.payment_method)}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="invoice-bills">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="w-100px">SKU</th>
                  <th className="w-50">Name</th>
                  <th>Shipping</th>
                  <th>Unit Price</th>
                  <th>Qty</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {order?.order_products?.map((orderProduct) => (
                  <tr>
                    <td>{orderProduct.product?.sku}</td>
                    <td>{orderProduct.product?.name}</td>
                    <td>
                      <Currency amount={orderProduct.shipping} />
                    </td>
                    <td>
                      <Currency
                        amount={orderProduct.subtotal / orderProduct.quantity}
                      />
                    </td>
                    <td>{orderProduct.quantity}</td>
                    <td>
                      <Currency amount={orderProduct.subtotal} />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3"></td>
                  <td colSpan="2">Subtotal</td>
                  <td>
                    <Currency amount={totalSubtotal} />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3"></td>
                  <td colSpan="2">Shipping fee</td>
                  <td>
                    <Currency amount={totalShipping} />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3"></td>
                  <td colSpan="2">Grand Total</td>
                  <td>
                    <Currency amount={totalSubtotal + totalShipping} />
                  </td>
                </tr>
              </tfoot>
            </table>
            <div className="nk-notes ff-italic fs-12px text-soft">
              Invoice was created on a computer and is valid without the
              signature and seal.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
