import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "alert",
  initialState: {
    token: null,
    roles: [],
    user: null,
    accessibleWebsites: [],
    expiredAt: null,
    isAuthenticated: false,
  },
  reducers: {
    onLoggedIn: (state, action) => {
      const data = action.payload;
      localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, data.token);
      return {
        ...state,
        token: data.token,
        roles: data.roles,
        accessibleWebsites: data.accessible_websites,
        user: data.user,
        expiredAt: data.expired_at,
        isAuthenticated: true,
      };
    },

    onLogout: (state, action) => {
      localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
      return {
        token: null,
        roles: [],
        user: null,
        expiredAt: null,
        isAuthenticated: false,
        accessibleWebsites: [],
      };
    },
    refreshToken: (state, action) => {
      const data = action.payload;
      localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
      localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, data.token);
      return {
        ...state,
        token: data.token,
        expiredAt: data.expired_at,
      };
    },
    initialize: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
