import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, DropdownItem, Tooltip } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import PageTable from "../../components/admin-page-components/PageTable";
import useRequest from "../../hooks/use-request";
import Content from "../../layouts/dashboard/content/Content";
import Head from "../../layouts/dashboard/head/Head";
import apiRoutes from "../../routes/api-routes";
import {
  searchParamsToObject,
  capitalizeText,
  imageKitLoader,
} from "../../utils/methods";
import CreatUpdateModal from "./partials/create-update-modal";
import HeadSection from "../../components/admin-page-components/HeadSection";
import Button from "../../components/button/Button";
import Swal from "sweetalert2";
import { addAlert } from "../../utils/alert";
import { toast } from "react-toastify";
import routes from "../../routes/routes-data";
import { useSelector } from "react-redux";
import Image from "../../components/image/Image";
import StatusBadge from "./partials/status-badge";
import Currency from "../../components/html/Currency";
import { EventRegistrationStatus } from "../../utils/statics";

const EventsIndex = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [events, setEvents] = useState([]);
  const { isLoading, sendRequest, error } = useRequest();
  const { sendRequest: sendRequestStatus, isLoading: statusIsLoading } =
    useRequest();

  let location = useLocation();

  let websiteOptions = useSelector(
    (store) => store.auth.accessibleWebsites
  ).map((web) => ({
    label: web.toLowerCase(),
    value: web,
  }));

  const getEvents = useCallback(async () => {
    let response = await sendRequest({
      url: apiRoutes.EVENT_INDEX,
      queryParams: {
        ...searchParamsToObject(searchParams),
        page: searchParams.get("page") ?? 1,
      },
    });
    if (response) {
      setEvents(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  }, [searchParams, sendRequest]);

  const onDeleteConfirm = async (id) => {
    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.EVENT_DELETE(id),
        method: "DELETE",
      });
      if (response) {
        addAlert(toast.TYPE.SUCCESS, "Event has been deleted successfully");
        getEvents();
      }
    }
    return true;
  };
  const onDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  const onStatusClick = async (eventId, status) => {
    if (!statusIsLoading) {
      let response = await sendRequestStatus({
        url: apiRoutes.EVENT_UPDATE_STATUS(eventId),
        method: "PATCH",
        body: {
          registration_status: status,
        },
      });
      if (response) {
        setEvents((prev) =>
          prev?.map((event) => {
            if (eventId === event.id) {
              event.registration_status = status;
              return event;
            } else {
              return event;
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    setEvents([]);
  }, [location.pathname]);

  useEffect(() => {
    getEvents();
  }, [getEvents, searchParams]);

  return (
    <>
      <Head title="Events"></Head>
      <Content>
        <HeadSection
          title={"Events"}
          right={
            <Button
              className="d-inline-flex"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              <Icon name="plus"></Icon>
            </Button>
          }
        />

        {
          <PageTable
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            searchPlaceholder={"Search by event name, id"}
            title={"Main Events"}
            isLoading={isLoading}
            error={error}
            headers={["event", "status", "fee", "website"]}
            actions={true}
            body={events?.map((event) => {
              let changeStatus =
                event.registration_status == EventRegistrationStatus.AVAILABLE
                  ? EventRegistrationStatus.BLOCKED
                  : EventRegistrationStatus.AVAILABLE;
              return [
                <span className="tb-product">
                  <Image
                    src={imageKitLoader(event.image?.path, 100, 60)}
                    alt="product"
                    className="thumb"
                    style={{
                      width: "100px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                  />
                  <div>
                    <p className=" text-capitalize" style={{ marginBottom: 0 }}>
                      On: {event.starting_at}
                    </p>
                    <p className="title text-capitalize">{event.name}</p>
                  </div>
                </span>,
                <StatusBadge status={event.registration_status} />,
                <Currency amount={event.registration_cost} />,
                capitalizeText(event.website),
                [
                  {
                    dropdown: (
                      <DropdownItem
                        key={"order_" + event.id + "_" + changeStatus}
                        tag="a"
                        href="#"
                        onClick={() => onStatusClick(event.id, changeStatus)}
                      >
                        <Icon
                          name={
                            changeStatus === EventRegistrationStatus.BLOCKED
                              ? "na"
                              : "list-check"
                          }
                        ></Icon>
                        <span>Mark as {capitalizeText(changeStatus)}</span>
                      </DropdownItem>
                    ),
                    tooltip: (
                      <li
                        key={"order_" + event.id + "_" + changeStatus}
                        className="nk-tb-action-hidden"
                        onClick={() => onStatusClick(event.id, changeStatus)}
                      >
                        <TooltipComponent
                          tag="a"
                          containerClassName="btn btn-trigger btn-icon"
                          id={"order_" + event.id + "_" + changeStatus}
                          icon={
                            changeStatus === EventRegistrationStatus.BLOCKED
                              ? "na"
                              : "list-check"
                          }
                          direction="top"
                          text={`Mark as ${capitalizeText(changeStatus)}`}
                        />
                      </li>
                    ),
                  },
                  {
                    dropdown: (
                      <DropdownItem
                        tag={Link}
                        to={routes.EVENT_REGISTRATIONS(event.id)}
                      >
                        <Icon name="user-check"></Icon>
                        <span>Registrations</span>
                      </DropdownItem>
                    ),
                    tooltip: (
                      <li className="nk-tb-action-hidden">
                        <TooltipComponent
                          tag={Link}
                          to={routes.EVENT_REGISTRATIONS(event.id)}
                          containerClassName="btn btn-trigger btn-icon"
                          id={"registrations" + event.id}
                          icon="user-check"
                          direction="top"
                          text="Registrations"
                        />
                      </li>
                    ),
                  },

                  {
                    dropdown: (
                      <DropdownItem
                        tag="a"
                        href="#"
                        onClick={() => {
                          setSelectedEvent(event);
                          setShowModal(true);
                        }}
                      >
                        <Icon name="edit"></Icon>
                        <span>Edit</span>
                      </DropdownItem>
                    ),
                    tooltip: (
                      <li
                        className="nk-tb-action-hidden"
                        onClick={() => {
                          setSelectedEvent(event);
                          setShowModal(true);
                        }}
                      >
                        <TooltipComponent
                          tag="a"
                          containerClassName="btn btn-trigger btn-icon"
                          id={"edit" + event.id}
                          icon="edit"
                          direction="top"
                          text="Edit Event"
                        />
                      </li>
                    ),
                  },
                  {
                    dropdown: (
                      <DropdownItem
                        tag="a"
                        href="#"
                        onClick={() => onDeleteClick(event.id)}
                      >
                        <Icon name="trash-empty"></Icon>
                        <span>Delete</span>
                      </DropdownItem>
                    ),
                    tooltip: (
                      <li
                        className="nk-tb-action-hidden"
                        onClick={() => onDeleteClick(event.id)}
                      >
                        <TooltipComponent
                          tag="a"
                          containerClassName="btn btn-trigger btn-icon"
                          id={"delete" + event.id}
                          icon="trash-empty"
                          direction="top"
                          text="Delete Event"
                        />
                      </li>
                    ),
                  },
                ],
              ];
            })}
            paginationData={paginationData}
            filterOptions={[
              {
                label: "website",
                name: "website",
                options: [
                  {
                    label: "all",
                    value: "",
                  },
                  ...websiteOptions,
                ],
              },
            ]}
          />
        }
      </Content>
      <CreatUpdateModal
        event={selectedEvent}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedEvent(null);
        }}
        onSuccess={() => getEvents()}
        websiteOptions={websiteOptions}
      />
    </>
  );
};

export default EventsIndex;
