import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SimpleBar from "simplebar-react";
import Swal from "sweetalert2";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  BlockDes,
  Row,
  Button,
  OverlineTitle,
  Icon,
} from "../../../components/Component";
import CheckboxRadio from "../../../components/input/checkbox-radio/checkbox-radio";
import ImageInput from "../../../components/input/image/image";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import { addAlert } from "../../../utils/alert";
import { capitalizeText } from "../../../utils/methods";
import { BannerTypes } from "../../../utils/statics";
let swal;
const CreatUpdateModal = ({
  show,
  onClose,
  banner,
  onSuccess,
  websiteOptions,
  type,
}) => {
  const { isLoading, sendFormRequest, validationErros, resetValidationErrors } =
    useRequest();

  const isSlot = type === BannerTypes.SLOT;
  const { inputs, setOne, setFields, reset } = useForm({
    image: "",
    primary_title: "",
    secondary_title: "",
    description: "",
    primary_action_label: "",
    primary_action_link: "",
    secondary_action_label: "",
    secondary_action_link: "",
    website: "",
  });

  useEffect(() => {
    if (banner) {
      setFields({
        image: banner.image
          ? {
              id: banner.image?.id,
              file: null,
              url: banner.image?.access_path,
            }
          : null,
        website: banner.website ?? "",
        primary_title: banner.primary_title ?? "",
        secondary_title: banner.secondary_title ?? "",
        description: banner.description ?? "",
        primary_action_label: banner.primary_action_label ?? "",
        primary_action_link: banner.primary_action_link ?? "",
        secondary_action_label: banner.secondary_action_label ?? "",
        secondary_action_link: banner.secondary_action_link ?? "",
      });
    }
  }, [banner, setFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const data = new FormData();
      data.append("primary_title", inputs.primary_title);
      data.append("secondary_title", inputs.secondary_title);
      data.append("description", inputs.description);
      data.append("primary_action_label", inputs.primary_action_label);
      data.append("primary_action_link", inputs.primary_action_link);
      data.append("secondary_action_label", inputs.secondary_action_label);
      data.append("secondary_action_link", inputs.secondary_action_link);
      data.append("website", inputs.website);
      data.append("image", inputs.image?.file ?? "");
      if (banner) {
        data.append("_method", "PATCH");
      }

      sendFormRequest({
        url: banner
          ? apiRoutes.BANNER_UPDATE(type, banner.id)
          : apiRoutes.BANNER_CREATE(type),
        method: banner ? "PATCH" : "POST",
        body: data,
      }).then((response) => {
        Swal.close();
        if (response) {
          reset();
          addAlert(
            toast.TYPE.SUCCESS,
            `Banner has been ${banner ? "updated" : "added"} successfully`
          );
          onClose();
          onSuccess();
        }
      });

      if (
        inputs.image?.type?.endsWith("mp4") ||
        inputs.image?.type?.endsWith("flv")
      ) {
        swal = Swal.fire({
          title: "Please Wait",
          text: "Video is uploading...",
          icon: "info",
          showCloseButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={() => {
          reset();
          resetValidationErrors();
          onClose();
        }}
        fullscreen
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            reset();
            resetValidationErrors();
            onClose();
          }}
          close={
            <button
              className="close"
              onClick={() => {
                reset();
                resetValidationErrors();
                onClose();
              }}
            >
              <Icon name="cross" />
            </button>
          }
        >
          {banner ? "Edit" : "Add"} Banner
        </ModalHeader>

        <form onSubmit={onSubmit}>
          <ModalBody>
            <Row className="g-3">
              <ImageInput
                accept={"video/* | image/*"}
                columnConfig={{ sm: 12 }}
                label="Banner Background"
                set={(key, value) => {
                  setOne(key, value);
                }}
                name="image"
                model={inputs.image}
                error={validationErros?.image}
                width="250px"
                removable={false}
              />

              <Input
                columnConfig={{ sm: 6 }}
                label="primary tite"
                set={setOne}
                name="primary_title"
                model={inputs.primary_title}
                error={validationErros?.primary_title}
              />
              <Input
                columnConfig={{ sm: 6 }}
                label="secondary title"
                set={setOne}
                name="secondary_title"
                model={inputs.secondary_title}
                error={validationErros?.secondary_title}
              />
              <Input
                columnConfig={{ sm: 6 }}
                label="description"
                set={setOne}
                name="description"
                model={inputs.description}
                error={validationErros?.description}
              />
              {isSlot ? (
                <Input
                  columnConfig={{ sm: 6 }}
                  label="Website"
                  name="website"
                  model={capitalizeText(inputs.website)}
                  readOnly
                />
              ) : (
                <Select
                  options={websiteOptions}
                  columnConfig={{ sm: 6 }}
                  label="Website"
                  set={setOne}
                  name="website"
                  model={inputs.website}
                  error={validationErros?.website}
                />
              )}
              <OverlineTitle>Primary Action</OverlineTitle>
              <Input
                columnConfig={{ sm: 6 }}
                label="label"
                set={setOne}
                name="primary_action_label"
                model={inputs.primary_action_label}
                error={validationErros?.primary_action_label}
              />
              <Input
                columnConfig={{ sm: 6 }}
                label="link"
                set={setOne}
                name="primary_action_link"
                model={inputs.primary_action_link}
                error={validationErros?.primary_action_link}
              />
              <OverlineTitle>Secondary Action</OverlineTitle>
              <Input
                columnConfig={{ sm: 6 }}
                label="label"
                set={setOne}
                name="secondary_action_label"
                model={inputs.secondary_action_label}
                error={validationErros?.secondary_action_label}
              />
              <Input
                columnConfig={{ sm: 6 }}
                label="link"
                set={setOne}
                name="secondary_action_link"
                model={inputs.secondary_action_link}
                error={validationErros?.secondary_action_link}
              />
            </Row>
          </ModalBody>
          <ModalFooter className="bg-light">
            <Button isLoading={isLoading}>
              {banner ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default CreatUpdateModal;
